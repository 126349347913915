<template>
  <div id="step_1" class="center">
    <div class="w-100" v-if="activeStep==='step_1'">
        <div class="text-center black-header relative-pos flex-between text-white">
            <div class="flex">
                <div @click="resetFormData" class="reset-form" v-if="activeStep !== 'step_6'">
                    <p>{{ $t('Reset') }}</p>
                </div>
                <SkeletonLoader class="small-loader mr-3" v-if="loading.attributes"/>
            </div>
            <h3>{{ $t('Step') }} 1 - {{ $t('Product') }}</h3>
            <div class="reset-form asana-message" :class="{ 'asana-report-sent': selectedValues.general.asanaReportSent }" @click="openConfiguratorPopup = !openConfiguratorPopup">
                <p class="text-right">{{ $t('Report issue') }}</p>
            </div>
        </div>
        <SkeletonLoader v-if="loading.steps"/>
        <div class="w-100 container" v-else ref="container">
            <div class="flex-center m-4" v-if="stepIsEmpty()">
                <h4>{{ $t('No attributes available in this step for') }} {{ selectedValues.general.productName }}.</h4>
            </div>
            <div v-if="selectedValues.general.beeProductId !== lapwingBeeId">
                <div
                    class="base-attribute consoles"
                    v-for="(productVersion, index) in filteredProductVersions" :key="index"
                >
                    <div class="flex width-28" :class="{'width-18': roughtProductVersion}">
                        <p>{{ productVersion.configurator_group_name }}</p>
                        <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['version']">
                            <svg-image
                                class="icon hover-icon"
                                icon="info"
                            />
                            <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['version']"></span>
                        </div>
                    </div>
                    <ul class="options console">
                        <li class="option mb-1"
                            v-if="!(selectedValues.general.beeProductId == strokLittleSisterExtendedBeeId && productVersion.id == finlessVersionId)"
                            @click="setSelectedVersion(false, productVersion)"
                            :class="{
                                active: reactiveSelectedValues.productVersions.hasOwnProperty(productVersion.id) && !productVersion.selected,
                            }"
                        >
                            <div>
                                <img
                                    v-if="productVersion.comparison_image_path !==null"
                                    v-bind:src="apiVizuloStorageUrl + productVersion.comparison_image_path"
                                    class="console-image"
                                    alt="product version"
                                >
                                <img
                                    v-else
                                    class="console-image"
                                    v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                                    alt="product version"
                                >
                                <div class="flex-column">
                                    <span>{{ productVersion.configurator_comparison_name }}</span>
                                    <span class="check"></span>
                                </div>
                            </div>
                            <div v-if="productVersion.power_temperature && reactiveProductConfigurator.product.powerTemperature" class="attribute-tooltip temperature-tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="temperature-low"
                                />
                                <div class="attribute-tooltiptext power-temperature">
                                    <div class="cell">
                                        <div>{{ $t('Power') }}</div>
                                        <div>{{ $t('Max Ta') }}</div>
                                    </div>
                                    <div class="cell" :key="index" v-for="(powerTemperatur, index) in reactiveProductConfigurator.product.powerTemperature">
                                        <div>
                                            {{ `${getMinPower(reactiveProductConfigurator.product.powerTemperature, index, productVersion)} ... ${ powerTemperatur.max_power } W` }}
                                        </div>
                                        <div>{{ `+${ powerTemperatur.max_temperature }` }}&deg;<p v-if="storeCode !== 'us'">C</p><p v-else>F</p></div>
                                    </div>
                                </div>
                            </div>
                            <div class="attribute-tooltip flex configurator-tooltip" v-if="productVersion.configurator_comparison_tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="info"
                                />
                                <span class="attribute-tooltiptext" v-html="productVersion.configurator_comparison_tooltip"></span>
                            </div>
                        </li>
                        <li class="option mb-1"
                            @click="setSelectedVersion(true, productVersion)"
                            :class="{
                                active: reactiveSelectedValues.productVersions.hasOwnProperty(productVersion.id) && productVersion.selected,
                                hide: reactiveSelectedValues.productVersions.hasOwnProperty(toolLessVersionId)&& reactiveSelectedValues.productVersions[toolLessVersionId].selected && productVersion.id == skirtsVersionId
                            }"
                        >
                            <div>
                                <img
                                    v-if="productVersion.image_path !==null"
                                    class="console-image"
                                    v-bind:src="apiVizuloStorageUrl + productVersion.image_path"
                                    alt="product version">
                                <img
                                    v-else
                                    class="console-image"
                                    v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                                    alt="product version">
                                <div class="flex-column">
                                    <span>{{ productVersion.configurator_name }}</span>
                                    <span class="check"></span>
                                </div>
                            </div>
                            <div v-if="productVersion.power_temperature && productVersion.power_temperature.length > 0" class="attribute-tooltip temperature-tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="temperature-low"
                                />
                                <div class="attribute-tooltiptext power-temperature">
                                    <div class="cell">
                                        <div>{{ $t('Power') }}</div>
                                        <div>{{ $t('Max Ta') }}</div>
                                    </div>
                                    <div class="cell" :key="index" v-for="(powerTemperatur, index) in productVersion.power_temperature">
                                        <div>
                                            {{ `${getMinPower(productVersion.power_temperature, index, productVersion)} ... ${ powerTemperatur.max_power } W` }}
                                        </div>
                                        <div>{{ `+${ powerTemperatur.max_temperature }` }}&deg;<p v-if="storeCode !== 'us'">C</p><p v-else>F</p></div>
                                    </div>
                                </div>
                            </div>
                            <div class="attribute-tooltip flex configurator-tooltip" v-if="productVersion.configurator_tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="info"
                                />
                                <span class="attribute-tooltiptext" v-html="productVersion.configurator_tooltip"></span>
                            </div>
                        </li>
                        <li class="option mb-1"
                            v-if="roughtProductVersion && productVersion.id === finlessVersionId"
                            @click="setSelectedVersion(true, roughtProductVersion, true)"
                            :class="{ active: reactiveSelectedValues.productVersions.hasOwnProperty(roughtProductVersion.id) && roughtProductVersion.selected }"
                        >
                            <div>
                                <img
                                    v-if="roughtProductVersion.image_path !== null"
                                    class="console-image"
                                    v-bind:src="apiVizuloStorageUrl + roughtProductVersion.image_path"
                                    alt="product version">
                                <img
                                    v-else
                                    class="console-image"
                                    v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                                    alt="product version">
                                <div class="flex-column">
                                    <span>{{ roughtProductVersion.configurator_name }}</span>
                                    <span class="check"></span>
                                </div>
                            </div>
                            <div v-if="roughtProductVersion.power_temperature && roughtProductVersion.power_temperature.length > 0" class="attribute-tooltip temperature-tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="temperature-low"
                                />
                                <div class="attribute-tooltiptext power-temperature">
                                    <div class="cell">
                                        <div>{{ $t('Power') }}</div>
                                        <div>{{ $t('Max Ta') }}</div>
                                    </div>
                                    <div class="cell" :key="index" v-for="(powerTemperatur, index) in roughtProductVersion.power_temperature">
                                        <div>
                                            {{ `${getMinPower(roughtProductVersion.power_temperature, index, roughtProductVersion)} ... ${ powerTemperatur.max_power } W` }}
                                        </div>
                                        <div>{{ `+${ powerTemperatur.max_temperature }` }}&deg;<p v-if="storeCode !== 'us'">C</p><p v-else>F</p></div>
                                    </div>
                                </div>
                            </div>
                            <div class="attribute-tooltip flex configurator-tooltip" v-if="roughtProductVersion.configurator_tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="info"
                                />
                                <span class="attribute-tooltiptext" v-html="roughtProductVersion.configurator_tooltip"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div
                class="base-attribute consoles"
                v-if="reactiveProductConfigurator.mountings && reactiveProductConfigurator.mountings.length > 0"
            >
                <div class="flex width-18">
                    <p>{{ $t('Mounting') }}</p>
                    <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['mounting']">
                        <svg-image
                            class="icon hover-icon"
                            icon="info"
                        />
                        <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['mounting']"></span>
                    </div>
                </div>
                <ul class="options console">
                    <li
                        class="option mb-2"
                        :class="{ active: reactiveSelectedValues.mounting && mounting.id == reactiveSelectedValues.mounting.id}"
                        @click="formDataSave('mounting', mounting, false, true)"
                        :key="mounting.id"
                        v-for="mounting in reactiveProductConfigurator.mountings">
                        <img
                            v-if="mounting.file_path"
                            v-bind:src="apiVizuloStorageUrl + mounting.file_path"
                            class="w-100 console-image"
                            :alt="mounting.name">
                        <img
                            v-else
                            class="w-100 console-image"
                            v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                            :alt="mounting.name">
                        <div class="flex-column">
                            <span>{{ mounting.name }}</span>
                            <span class="check"></span>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hide">{{ reactiveSelectedValues.consolesLoaded }}</div>
            <transition name="fade">
                <div class="base-attribute consoles"  v-if="reactiveProductConfigurator.consoles" :class="{hide : reactiveProductConfigurator.consoles && reactiveProductConfigurator.consoles.length < 2}">
                    <div class="flex width-18">
                        <p>{{ $t('Console') }}</p>
                        <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['console']">
                            <svg-image
                                class="icon hover-icon"
                                icon="info"
                            />
                            <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['console']"></span>
                        </div>
                    </div>
                    <ul class="options console">
                        <li
                            class="option mb-2"
                            :class="{ active: reactiveSelectedValues.console && console.name ==  reactiveSelectedValues.console.name}"
                            @click="formDataSave('console', console, false, true)"
                            :key="console.id"
                            v-for="console in reactiveProductConfigurator.consoles">
                            <img
                                v-if="console.file_path"
                                v-bind:src="apiVizuloStorageUrl + console.file_path"
                                class="w-100 console-image"
                                :alt="console.name">
                            <img
                                v-else
                                class="w-100 console-image"
                                v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                                :alt="console.name">
                            <div class="flex-column">
                                <span>{{ console.name }}</span>
                                <span class="check"></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
        <div class="warning-message flex-center" v-if="selectedValues.general.unfinishedItems && selectedValues.general.unfinishedItems.length > 0">
            <p class="mr-2">{{ $t('Required fields') }}:</p>
            <p v-html="selectedValues.general.unfinishedItems.join(', ')"></p>
        </div>
        <IssueReportPopup
            :visible="openConfiguratorPopup"
            :userComment="userComment"
            @close="openConfiguratorPopup = !openConfiguratorPopup"
            @sendReport="sendReport"
        />
    </div>
    <div v-if="reactiveSelectedValues.isComplete && selectedValues.general.activeStep === step" class="arrow-down" @click="changeActiveStep('step_2')">
        <svg-image
            class="icon hover-icon"
            icon="chevron_down"
            height="36"
            width="36"
        />
    </div>
    <div
        v-if="activeStep !== 'step_1' && activeStep !== 'step_6'"
        class="base-attribute-button base-attribute-button-close"
        @click="changeActiveStep('step_1')"
    >
        <span>
            {{ $t('Step') }} 1 - {{ $t('Product') }}
        </span>
    </div>
  </div>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useProductConfigurator } from '@/modules/vizulo/productConfigurator';
import {
    computed,
    ref
} from '@nuxtjs/composition-api';
import { useVizulo } from '~/composables';
import IssueReportPopup from '~/components/Common/IssueReportPopup.vue';

export default defineComponent({
    name: 'ProductVersions',
    components: {
        IssueReportPopup,
    },
	props: {
        activeStep: String,
        isCloseToActiveStep: Boolean,
        isNextStep: Boolean,
        storeCode: String,
	},
    setup(props, {emit}) {
        const { productConfigurator, updateSelectedValues, selectedValues, loading, resetFormData, createBugReportInAsana } = useProductConfigurator();
        const { getApiVizuloStorage } = useVizulo();
        const apiVizuloStorageUrl = getApiVizuloStorage();
        const step = 'step_1';
        const floodVersionId = 65;
        const finlessVersionId = 66;
        const toolLessVersionId = 67;
        const skirtsVersionId = 87;
        const ekoVersionId = 68;
        const roughVersionId = 85;
        const strokLittleSisterExtendedBeeId = 50;
        const lapwingBeeId = 48;
        const openConfiguratorPopup = ref(false);
        const userComment = ref({});

        const reactiveProductConfigurator = computed(() => {
            return productConfigurator.value.step_1 ?? [];
        });

        const reactiveSelectedValues = computed(()=>{
            return selectedValues.value.step_1 ?? [];
        })

        const filteredProductVersions = computed(() => {
            return reactiveProductConfigurator.value.productVersions.filter((version) => {
                return version.id !== ekoVersionId && version.id !== roughVersionId;
            })
        })

        const roughtProductVersion = computed(() => {
            return reactiveProductConfigurator.value.productVersions.find((version) => {
                return version.id === roughVersionId;
            })
        })

        const setSelectedVersion = (selected, productVersion, settingRought) => {
            if (productVersion.id === floodVersionId) {
                selectedValues.value.step_5.mountingScrewLength = null;
            }

            // Exception for incompatibility for tooless version with skirt
            if (productVersion.id == toolLessVersionId) {
                if (reactiveSelectedValues.value.productVersions.hasOwnProperty(skirtsVersionId)&& reactiveSelectedValues.value.productVersions[skirtsVersionId].selected) {
                    selectedValues.value.step_1.productVersions[skirtsVersionId].selected = false;
                }
            }

            if (settingRought) {
                productVersion.selected = selected;
                selectedValues.value.step_1.productVersions[productVersion.id] = productVersion;
                delete selectedValues.value.step_1.productVersions[finlessVersionId];
            } else {
                productVersion.selected = selected;
                selectedValues.value.step_1.productVersions[productVersion.id] = productVersion;

                if (productVersion.id === finlessVersionId && reactiveSelectedValues.value.productVersions.hasOwnProperty(roughVersionId)) {
                    delete selectedValues.value.step_1.productVersions[roughVersionId];
                }
            }

            formDataSave('productVersions', selectedValues.value.step_1.productVersions, false, true);
            formDataSave('versionsAreEdited', true);
        };

        const formDataSave = async (updatedAttribute, value = '', cancelUpdate = false, selectedByUser = false) => {
            updateSelectedValues(step, updatedAttribute, value, cancelUpdate, selectedByUser);
        };

        const changeActiveStep = (value) => {
            emit('changeStep', (value));
        }

        const sendReport = (sentWithSubmitButton) => {
            createBugReportInAsana(userComment.value.message ?? '', sentWithSubmitButton);
            openConfiguratorPopup.value = false;
        }

        const stepIsEmpty = () => {
            if (reactiveProductConfigurator.value.productVersions?.length < 2 && reactiveProductConfigurator.value.mountings?.length < 2 && reactiveProductConfigurator.value.consoles?.length < 2) {
                return true;
            }

            return false;
        }

        const getMinPower = (powerTemperatur, index, productVersion) => {
            if (powerTemperatur[index-1]) {
                return powerTemperatur[index-1]['max_power'] + 1;
            } else {
                if (productVersion.min_power) {
                    return productVersion.min_power;
                }

                return 5;
            }
        }

        return {
            setSelectedVersion,
            formDataSave,
            changeActiveStep,
            resetFormData,
            stepIsEmpty,
            sendReport,
            getMinPower,
            selectedValues,
            apiVizuloStorageUrl,
            reactiveProductConfigurator,
            reactiveSelectedValues,
            loading,
            filteredProductVersions,
            step,
            strokLittleSisterExtendedBeeId,
            lapwingBeeId,
            finlessVersionId,
            openConfiguratorPopup,
            userComment,
            roughtProductVersion,
            toolLessVersionId,
            skirtsVersionId
        };
    }
});
</script>
<style lang="scss" scoped>
.images {
    img {
        border: 1px solid var(--_c-gray-accent);
        width: 100%;
        cursor: pointer;
        background: var(--c-white);

        &.active {
            border: 1px solid var(--c-black);
            background: none ;
        }
    }

  .image-container {
    position: relative;
    width: 100%;
    padding: 16px 26px;

    &:last-child {
            border-right: none !important;
        }

    .flex {
        justify-content: center;
    }

    p {
        white-space: nowrap;
    }
  }

  @include for-mobile {
    min-height: 10vh
  }
}

.width-18 {
    max-width: 18rem;
}

.base-attribute-button {
    border-top: 1px solid var(--_c-gray-secondary);
}

.base-attribute {
    border-bottom: none !important;
    position: relative;

    &::after {
        content: '';
        width: 100%;
        position: absolute;
        top: 0px;
        border-bottom: 1px solid var(--_c-gray-accent);
    }

    &:first-of-type::after {
        content: none;
    }
}

.options {
    .image-container {
        margin-bottom: 16px !important;
        padding: 16px 26px 0 26px;
    }
  }

.standard {
    .active {
        background-color: var(--c-lighter-gray) !important;
    }
    .image-container {
        padding: 0 26px 16px 26px;
        flex-basis: auto;
    }
}

.versions {
    .image-container {
        padding: 0 26px 16px 26px;
    }
}

.options {
    padding: 0;
    margin: 0;

    .option {
        list-style: none;
        font-size: 14px;
        line-height: 18px;
        background-color: var(--c-white) !important;
        cursor: pointer;
        font-family: var(--font-family--primary-bold);
        padding: 9px 12px;
        border: 1px solid var(--_c-gray-accent);
        margin: 0 15px 0 0;
        transition: all ease-in 300ms;
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: initial !important;
        align-items: center;
        min-width: 142px;
        max-width: 142px;

        @media (min-width: 420px) {
            min-width: 180px;
            max-width: 180px;
        }

        @media (min-width: 490px) {
            min-width: 215px;
            max-width: 215px;
        }

        @media (min-width: 991px) {
            min-width: 256px;
            max-width: 256px;
        }

        &:hover {
            border: 1px solid var(--c-black);
            box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
            -webkit-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
            -moz-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
        }

        &.active {
            border: 1px solid var(--c-black);
            box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
            -webkit-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
            -moz-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
        }

        &:focus {
            outline: none;
        }

        .console-image {
            height: 91px;
            width: 122px;
            background: none;
            border: none;

            @media (min-width: 400px) {
                height: 102px;
                width: 136px;
            }

            @media (min-width: 450px) {
                height: 120px;
                width: 160px;
            }

            @media (min-width: 991px) {
                height: 150px;
                width: 230px;
            }
        }

        .check {
            display: block;
            border: 1px solid var(--c-black);
            border-radius: 100%;
            height: 20px;
            width: 20px;
            z-index: 1;
            transition: border .25s linear;
            -webkit-transition: border .25s linear;
            position: relative;
            float: left;
            margin-top: 10px;
        }

        &.active {
            .check::before {
                display: block;
                position: absolute;
                content: '';
                border-radius: 100%;
                height: 12px;
                width: 12px;
                top: 3px;
                left: 3px;
                margin: auto;
                background: var(--c-black);
                transition: background 0.25s linear;
                -webkit-transition: background 0.25s linear;
            }
        }
    }
}

.temperature-tooltip {
    height: 30px;
    width: 30px;
    position: absolute;
    left: 0;
    top: 0.5rem;

    p {
        margin: 0 !important;
    }

    .attribute-tooltiptext {
        margin: 0 !important;

        @media (min-width: 999px) {
            position: absolute;
            top: 1.5rem !important;
            left: 1rem !important;
            transform: none;
        }
    }
}

.power-temperature {
    display: flex;
    flex-direction: column;

    .cell {
        display: flex;
        justify-content: space-between;
        margin-top: 0.3rem;

        &:first-child {
            margin-top: 0;
        }

        div {
            display: flex;
            width: 100px;

            &:nth-child(2) {
                width: 50px;
                text-align: left;
                margin-left: 0.7rem;
            }
        }
    }
}


.configurator-tooltip {
    position: absolute;
    top: 0.5rem !important;
    right: 0.5rem !important;

    .attribute-tooltiptext {
        right: 1rem;
        top: 1rem;
    }
}

.width-28 {
    width: 100%;

    @media (min-width: 991px) {
        width: 20rem;
    }

    @media (min-width: 1400px) {
        width: 28rem;
    }
}

.w-250 {
    width: 250px;
}

.flex-start {
    flex-direction: column;
    align-items: flex-start !important;
}

.product-version {
    width: calc((100% - 72px)/4);
}

.invisible {
    visibility: hidden;
}

.vertical-splitter-top, .vertical-splitter-bottom {
    position: relative;
    left: 26px;
    bottom: 0;
    height: 94%;
    width: 1px;
    background: var(--_c-gray-secondary);
}

.vertical-splitter-top {
    margin-top: 50px;
}

.vertical-splitter-bottom {
    margin-bottom: 70px;
}

.flex-around {
    display: flex;
    justify-content: space-around !important;
    flex-direction: column !important;
}

.flex-column {
    span {
        text-align: center;
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.7s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>