<template>
    <div class="container mb-9 mt-6">
        <h2>{{ $t('Claim and paid service request submission form') }}</h2>
        <div class="steps-container">
            <transition :name="transitionName" mode="out-in">
                <Step1
                    v-if="activeStep === 1"
                    :key="1"
                    :orders="orders"
                    @addOrder="addOrder"
                    @removeOrder="removeOrder"
                />
                <Step2
                    v-if="activeStep === 2"
                    :key="2" :active-item-index="activeItemIndex"
                    :orders="orders"
                    :displayIssuesWarning="displayIssuesWarning"
                    :mainFormFields="mainFormFields"
                    @updateIssues="updateIssues"
                    @removeIssue="removeIssue"
                    @saveMainForm="saveMainForm"
                />
                <Step3
                    v-if="activeStep === 3"
                    :key="3"
                    :applicantDetails="applicantDetails"
                    :applicationSubmitted="applicationFinished"
                    :showFinalStepWarning="showFinalStepWarning"
                    :orders="orders"
                    @saveApplicantDetails="saveApplicantDetails"
                />
            </transition>
        </div>
        <div class="flex-end mt-3">
            <div v-if="activeStep !== 1 && !applicationFinished" @click="changeStep(-1)" class="change-step button button-secondary medium ml-3">{{ $t('Back') }}</div>
            <div v-if="showNextStep && activeStep !== 3" @click="changeStep(1)" class="change-step button button-secondary medium ml-3">{{ $t('Next') }}</div>
            <div v-if="activeStep === 3" @click="prepareClaimSubmission" class="change-step button button-secondary medium ml-3">{{ $t('Submit') }}</div>
        </div>
        <div v-if="applicationSubmitted">
            <SfModal
                :visible="applicationSubmitted"
                class="datasheet-modal"
                @close="applicationSubmitted = false"
            >
                <div id="modal" class="issue-report">
                    <div class="container">
                        <h3>{{ $t('Claim / paid service request submitted') }}</h3>
                        <p>{{ $t('You will recieve an email with claim number shortly. Please follow its instructions') }}</p>
                        <p>{{ $t('Email') }}: {{ applicantDetails.email }}</p>
                    </div>
                </div>
            </SfModal>
        </div>
        <div v-if="failedToSubmit">
            <SfModal
                :visible="failedToSubmit"
                class="datasheet-modal"
                @close="failedToSubmit = false"
            >
                <div id="modal" class="issue-report">
                    <div class="container warning-message">
                        <h3>{{ $t('Claim / paid service request failed to submit') }}</h3>
                        <p>{{ $t('Please contact sales@vizulo.com to resolv issue.') }}</p>
                    </div>
                </div>
            </SfModal>
        </div>
    </div>
</template>
<script type="module">
import {
	ref,
	defineComponent,
    computed
} from '@nuxtjs/composition-api';
import Step1 from '~/modules/vizulo/claimSubmissions/components/Step1.vue';
import Step2 from '~/modules/vizulo/claimSubmissions/components/Step2.vue';
import Step3 from '~/modules/vizulo/claimSubmissions/components/Step3.vue';
import { useClaimSubmission } from '~/modules/vizulo/claimSubmissions/index';
import { SfModal } from '@storefront-ui/vue';

export default defineComponent({
    name: 'ClaimSubmission',
	components: {
        Step1,
        Step2,
        Step3,
        SfModal
	},
	setup() {
        const activeStep = ref(1);
        const transitionName = ref('slide');
        const activeItemIndex = ref(0);
        const orders = ref([]);
        const excludedKeys = ['project', 'customer_order_number', 'customer'];
        const displayIssuesWarning = ref(false);
        const { submitClaims, applicationSubmitted, failedToSubmit } = useClaimSubmission();
        const applicantDetails = ref({
            full_name: '',
            email: '',
            telephone: '',
            vizulo_rep_email: '',
        });

        const showFinalStepWarning = ref(false);

        const mainFormFields = ref({
            endUser: '',
            quantity: '',
            preferredCourseOfAction: '',
        });

        const changeStep = (stepIndex) => {
            if (activeStep.value === 2) {
                let correctItemIndex = setCorrectItemIndex(stepIndex);

                if (!correctItemIndex) {
                    return;
                }
            }

            if (activeStep.value === 2 && stepIndex > 0) {
                let hasEmptyFields = checkIfEmptyFields();

                if (hasEmptyFields) {
                    return;
                }
            }

            if (stepIndex > 0) {
                if (activeStep.value < 3) {
                    activeStep.value ++;
                }
            } else {
                if (activeStep.value > 1) {
                    activeStep.value --;
                }
            }
        }

        const checkIfEmptyFields = () => {
            let hasEmptyFields = false;
            const order = orders.value[activeItemIndex.value];
            const emptyFields = Object.keys(order).filter(
                key => !excludedKeys.includes(key) && (!order[key])
            );

            if (emptyFields.length > 0) {
                if (order.issues.length === 0) {
                    displayIssuesWarning.value = true;
                }
                hasEmptyFields = true
            }

            return hasEmptyFields;
        }

        const setCorrectItemIndex = (value) => {
            if (value > 0) {
                if (activeItemIndex.value < orders.value.length - 1) {
                    let hasEmptyFields = checkIfEmptyFields();

                    if (hasEmptyFields) {
                        return;
                    }
                    activeItemIndex.value++;
                    setMainFormFields(orders.value[activeItemIndex.value]);

                    return false;
                }

                return true;
            } else {
                if (activeItemIndex.value > 0) {
                    activeItemIndex.value--;
                    setMainFormFields(orders.value[activeItemIndex.value]);

                    return false;
                }

                return true;
            }
        }

        const setMainFormFields = (order) => {
            mainFormFields.value = {
                endUser: order.endUser,
                quantity: order.quantity,
                preferredCourseOfAction: order.preferredCourseOfAction,
            };
        }

        const addOrder = (order) => {
            order.endUser = '';
            order.preferredCourseOfAction = '';
            order.quantity = '';
            order.issues = [];

            orders.value = [...orders.value, order];
        }

        const removeOrder = (index) => {
            orders.value.splice(index, 1);
        }

        const removeIssue = (index) => {
            orders.value[activeItemIndex.value].issues.splice(index, 1);
        }

        const updateIssues = (newIssues) => {
            if (orders.value[activeItemIndex.value]) {
                // Ensure reactivity by replacing the entire object
                displayIssuesWarning.value = false;

                orders.value[activeItemIndex.value] = {
                    ...orders.value[activeItemIndex.value],
                    issues: [...newIssues]
                };

                // Force Vue to detect the change
                orders.value = [...orders.value];
            }
        };

        const saveMainForm = (formData) => {
            orders.value[activeItemIndex.value] = {
                ...orders.value[activeItemIndex.value],
                ...formData
            };
        }

        const showNextStep = computed(() => {
            if (activeStep.value) {
                if (activeStep.value === 1) {
                    return orders.value.length > 0;
                } else {
                    return true;
                }
            }

            return false;
        });

        const applicationFinished = computed(() => {
            if (applicationSubmitted.value) {
                orders.value = [];
                mainFormFields.value = {};
                activeStep.value = 1;

                return true;
            }

            return false;
        });

        const saveApplicantDetails = (aplicantDetails) => {
            applicantDetails.value = aplicantDetails;
        };

        const prepareClaimSubmission = () => {
            const emptyFields = Object.entries(applicantDetails.value).filter(([key, value]) => !value && key !== 'vizulo_rep_email');

            if (emptyFields.length) {
                showFinalStepWarning.value = true;

                return;
            } else {
                showFinalStepWarning.value = false;
                submitClaims(orders.value, applicantDetails.value);
            }
        }

		return {
            activeStep,
            transitionName,
            activeItemIndex,
            orders,
            addOrder,
            changeStep,
            removeOrder,
            updateIssues,
            removeIssue,
            showNextStep,
            displayIssuesWarning,
            submitClaims,
            saveApplicantDetails,
            applicantDetails,
            applicationFinished,
            saveMainForm,
            mainFormFields,
            applicationSubmitted,
            failedToSubmit,
            prepareClaimSubmission,
            showFinalStepWarning
		};
	},
});

</script>

<style lang="scss" scoped>
    .slide-enter-active,
    .slide-leave-active {
        transition: transform 0.5s ease;
    }

    .slide-enter-from {
        transform: translateX(100%);
    }

    .slide-leave-to {
        transform: translateX(-100%);
    }
    ::v-deep .sf-input__error-message {
        word-wrap: break-word;  /* Ensure long messages wrap to next line */
        white-space: normal;    /* Allow text to break into the next line */
        display: block;         /* Ensure the error message takes up its own line */
        width: 100%;            /* Prevent it from overflowing */
    }

    ::v-deep .attribute-tooltip {
        position: absolute;
        top: 16px;
        right: 1.5rem;
        padding-right: 16px;
        z-index: 10;
        max-width: 26px;
        cursor: pointer;
    }

    ::v-deep .attribute-tooltip .attribute-tooltiptext {
        position: absolute;
        right: 40px;
        bottom: 20px;
        padding: 12px;
        z-index: 10;
        visibility: hidden;
        background-color: var(--c-lighter-gray);
        border: 1px solid var(--c-light-gray);
        border-radius: 1rem;
        text-align: left;
        padding: 1rem 1rem;
        max-width: 100%;
        width: max-content;
        color: var(--c-black) !important;
        box-shadow: 3px 4px 7px 0px rgba(0,0,0,0.53);
        -webkit-box-shadow: 3px 4px 7px 0px rgba(0,0,0,0.53);
        -moz-box-shadow: 3px 4px 7px 0px rgba(0,0,0,0.53);

        @media (min-width: 500px) {
            max-width: 360px;
        }
        height: fit-content;
    }

    ::v-deep .attribute-tooltip:hover .attribute-tooltiptext, .typical-value:hover .typical-attribute-tooltiptext {
        visibility: visible;
    }

    ::v-deep .sf-modal__content {
        padding: 2rem 4rem;
    }

    ::v-deep table.vgt-table {
        border: none;
        width: 100% !important;
        border-collapse: separate;
        border-spacing: 0;

        thead {
            th {
                background: none;
                border: none;
                padding: 15px 15px 20px 0;

                span {
                    font-size: 12px;
                    line-height: 12px;
                    font-family: var(--font-family--primary-bold);
                    position: relative;
                    white-space: nowrap;

                    .arrow {
                        margin-left: -15px;
                        padding-left: 0;
                    }

                    strong {
                        position: absolute;
                        left: 0;
                        bottom: -16px;
                        color: #737373;
                        font-family: var(--font-family--primary);
                        font-weight: normal;
                        padding-left: 15px;
                    }
                }

                &.sortable {
                    span {
                        span {
                            left: 14px;
                            top: 15px;
                            position: absolute;
                            transform: rotate(90deg);
                        }
                    }

                    &.sorting-desc {
                        span {
                            span {
                                transform: rotate(-90deg);
                            }
                        }
                    }
                }

                &:not(.sortable) {
                    span {
                        span {
                            display: none;
                        }
                    }
                }

                &.sortable::before,
                &.sortable::after {
                    display: none;
                }

                .svg-image {
                    float: none;
                    margin-left: 8px;
                }
            }

            tr:nth-child(2) {
                display: none;
            }

            @media (max-width: 991px) {
                display: none;
            }
        }

        tbody {
            tr {
                &:first-child {
                    td {
                        border-top: 1px solid #EAEAEA;

                        @media (max-width: 991px) {
                            border-top: none;
                        }
                    }
                }

                @media (max-width: 991px) {
                    border: 1px solid var(--c-black);
                    padding: 10px;
                }

                td {
                    @media (max-width: 991px) {
                        display: block;
                        padding: 8px 0;

                        &:before {
                            padding-left: 0;
                        }

                        &:last-child {
                            @media (max-width: 991px) {
                                border-bottom: none;
                            }
                        }
                    }

                    i {
                        color: var(--c-light-gray);
                    }
                }
            }
        }

        td {
            border: none;
            border-bottom: 1px solid #EAEAEA;
            padding: 15px 15px 15px 0;
            font-size: 14px;

            span {
                span {
                    text-decoration: underline;
                    display: block;
                }
            }
        }
    }

    ::v-deep .vgt-wrap__footer {
        background: none;
        border: none;
        text-align: center;

        .footer__navigation {
            float: none !important;

            .chevron {
                display: none;
            }
        }

        .footer__navigation__page-info__current-entry {
            margin: 0 4px;
        }
    }
</style>
