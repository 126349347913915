<template>
  <div id="step_2" class="center">
    <div v-if="activeStep==='step_2'" class="w-100">
        <div class="text-center black-header w-100 relative-pos flex-between text-white">
            <div class="flex">
                <div @click="resetFormData" class="reset-form" v-if="activeStep !== 'step_6'">
                    <p>{{ $t('Reset') }}</p>
                </div>
                <SkeletonLoader class="small-loader mr-3" v-if="loading.attributes"/>
            </div>
            <h3>{{ $t('Step') }} 2 - {{ $t('Light') }}</h3>
            <div class="reset-form asana-message" :class="{ 'asana-report-sent': selectedValues.general.asanaReportSent }" @click="openConfiguratorPopup = !openConfiguratorPopup">
                <p class="text-right">{{ $t('Report issue') }}</p>
            </div>
        </div>
        <SkeletonLoader v-if="loading.steps"/>
            <div class="w-100 container" v-else>
                <div  class="base-attribute consoles" v-for="(productVersion, index) in filteredProductVersions" :key="index">
                    <div class="flex width-28" >
                        <p>{{ productVersion.configurator_group_name }}</p>
                        <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['lumen_depreciation']">
                            <svg-image
                                class="icon hover-icon"
                                icon="info"
                            />
                            <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['lumen_depreciation']"></span>
                        </div>
                    </div>
                    <ul class="options console">
                        <li
                            class="special-option"
                            :class="{ active: reactiveSelectedProductVersions.hasOwnProperty(productVersion.id) && !productVersion.selected }"
                            @click="setSelectedVersion(false, productVersion)"
                        >
                            <span>{{ productVersion.configurator_comparison_name }}</span>
                            <div class="attribute-tooltip flex" v-if="productVersion.configurator_comparison_tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="info"
                                />
                                <span class="attribute-tooltiptext" v-html="productVersion.configurator_comparison_tooltip"></span>
                            </div>
                        </li>
                        <li
                            class="special-option"
                            :class="{ active: reactiveSelectedProductVersions.hasOwnProperty(productVersion.id) && productVersion.selected }"
                            @click="setSelectedVersion(true, productVersion)"
                        >
                            <span>{{ productVersion.configurator_name }}</span>
                            <div class="attribute-tooltip flex" v-if="productVersion.configurator_tooltip">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="info"
                                />
                                <span class="attribute-tooltiptext" v-html="productVersion.configurator_tooltip"></span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="lens-template base-attribute align-items-end flex-column ">
                    <div class="flex-start w-100">
                        <p>{{ $t('Lens') }}</p>
                        <div class="flex-between filters" v-if="reactiveProductConfigurator.lenses.length > 0">
                            <input type="search" name="search-lens" placeholder="Search..." v-model="searchInput" class="lens-search" @keyup="findItems()">
                        </div>
                    </div>
                    <div class="filters mt-2" v-if="reactiveProductConfigurator.lenses.length > 0">
                        <div class="base-attribute align-items-start" v-if="reactiveProductConfigurator.ledModuleCategories.length > 1">
                            <div class="flex vw-10">
                                <p>{{ $t('LED module category') }}</p>
                                <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['ledModuleCategory']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['ledModuleCategory']"></span>
                                </div>
                            </div>
                            <ul class="options">
                                <li
                                    class="option w-8"
                                    @click="filterLenses('ledModuleCategory', category)"
                                    :class="{ active: activeFilters.ledModuleCategory.includes(category) }"
                                    v-for="(category, index) in reactiveProductConfigurator.ledModuleCategories" :key="index">
                                    <img :alt="category" :src="require(`~/assets/images/modules/${category}.svg`)">
                                    <span class="das">{{ category }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="base-attribute" v-if="reactiveProductConfigurator.lensApplicationGroups.length > 2">
                            <div class="flex vw-10">
                                <p>{{ $t('Application') }}</p>
                                <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['application']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['application']"></span>
                                </div>
                            </div>
                            <ul class="options">
                                <li
                                    class="option"
                                    @click="filterLenses('application', group)"
                                    :class="{ active: activeFilters.application.includes(group) }"
                                    v-for="(group, index) in reactiveProductConfigurator.lensApplicationGroups" :key="index">
                                    <span class="font-weight-normal">{{ group }}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-if="reactiveProductConfigurator.lenses.length <= 14" class="mb-4"></div>
                        <div class="base-attribute" v-if="openFilters && reactiveProductConfigurator.lenses.length > 14">
                            <div class="flex vw-10">
                                <p>{{ $t('LED density') }}</p>
                                <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['ledDensity']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['ledDensity']"></span>
                                </div>
                            </div>
                            <ul class="options">
                                <li
                                    class="option"
                                    @click="filterLenses('ledDensity', density.id)"
                                    :class="{ active: activeFilters.ledDensity.includes(density.id) }"
                                    v-for="density in reactiveProductConfigurator.ledDensity" :key="density.id">
                                    <span class="font-weight-normal">{{ density.name }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="base-attribute" v-if="openFilters && reactiveProductConfigurator.lenses.length > 14">
                            <div class="flex vw-10">
                                <p>{{ $t('Distribution type') }}</p>
                                <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['distributionType']">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="info"
                                    />
                                    <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['distributionType']"></span>
                                </div>
                            </div>
                            <ul class="options">
                                <li
                                    @click="filterLenses('distributionType', distributionType)"
                                    :class="{ active: activeFilters.distributionType.includes(distributionType) }"
                                    class="option"
                                    v-for="distributionType, index in reactiveProductConfigurator.distributionTypes" :key="index">
                                    <span>{{ distributionType }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="flex justify-content-center my-4" v-if="reactiveProductConfigurator.lenses.length > 14">
                            <button  v-if="openFilters" @click="manageSecondaryFiltersOnClose()" class="btn small">
                                {{ $t('Hide other filters') }}
                            </button>
                            <button  v-else @click="manageSecondaryFiltersOnClose()" class="btn small">
                                {{ $t('Show other filters') }}
                            </button>
                        </div>
                    </div>
                    <div class="flex-end mr-3 mb-2 relative-pos w-100 overflow-auto" v-if="reactiveProductConfigurator.lenses.length > 0">
                        <div class="document-missing-text flex-center" v-if="showLensTooltip">
                            <svg-image
                                class="icon hover-icon"
                                icon="document-missing"
                                height="22px"
                                width="22px"
                            />
                            <p class="m-0 ml-2">{{ $t('Lens is missing photometric files!') }}</p>
                        </div>
                        <div class="configurator-mode">
                            <div class="m-0 attribute-tooltip basic" :class="{ 'active': !showIsolineImages }" @click="showIsolineImages = false">
                                {{ $t('Polar view') }}
                                <span v-if="showIsolineImages" class="attribute-tooltiptext extra-options">{{ $t('Switch to polar view.') }}</span>
                            </div>
                            <div class="m-0 attribute-tooltip advanced" :class="{ 'active': showIsolineImages }" @click="showIsolineImages = true">
                                {{ $t('Isoline view') }}
                                <span v-if="!showIsolineImages" class="attribute-tooltiptext extra-options">{{ $t('Switch to isoline view.') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="flex baseline w-100">
                        <div v-if="reactiveSelectedValues.lens" class="selected-lens">
                            <div class="flex-between w-100 mt-2 px-2">
                                <span>{{ reactiveSelectedValues.lens.name }}</span>
                                <span class="mx-1">{{reactiveSelectedValues.lens.distribution_type}}</span>
                            </div>
                            <div class="images">
                                <div class="light-border">
                                    <img :alt="reactiveSelectedValues.lens.name" :src="apiVizuloUrl + reactiveSelectedValues.lens.file_path">
                                </div>
                                <div class="light-border trimmed">
                                    <img
                                        v-if="getIsolineFilePath(reactiveSelectedValues.lens.isoline_diagram_file_path) !== ''"
                                        :src="apiVizuloUrl + getIsolineFilePath(reactiveSelectedValues.lens.isoline_diagram_file_path)"
                                    >
                                    <img
                                        v-else
                                        class="w-100"
                                        alt="console"
                                        v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                                    >
                                </div>
                            </div>
                        </div>
                        <div v-else></div>
                        <div v-if="reactiveProductConfigurator.lenses.length > 0">
                            <ul class="lens options" v-if="(isFiltersActive || filteredLenses.length > 0 ) || searchInput.length > 0">
                                <li
                                    @click="formDataSave('lens', lens, false, true)"
                                    class="option"
                                    :class="{ active: reactiveSelectedValues.lens && reactiveSelectedValues.lens.id == lens.id, 'px-2' : showIsolineImages }"
                                    :key="lens.id"
                                    v-for="lens in filteredLenses"
                                >
                                    <span>{{ lens.name }}</span>
                                    <img
                                        v-if="!showIsolineImages || (showIsolineImages && getIsolineFilePath(lens.isoline_diagram_file_path) !== '')"
                                        :src="`${apiVizuloUrl}${showIsolineImages ? getIsolineFilePath(lens.isoline_diagram_file_path) : lens.file_path}`"
                                        :class="{'isoline-image': showIsolineImages}"
                                    >
                                    <img
                                        v-else
                                        class="w-100 h-63"
                                        v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                                        :alt="lens.name"
                                    >
                                    <span :class="{ 'm-0' : showIsolineImages }" class="check"></span>
                                    <div class="document-missing attribute-tooltip"
                                        @mouseover.native="showLensTooltip = true"
                                        @mouseleave.native="showLensTooltip = false"
                                        v-if="lens.no_photometric_file"
                                    >
                                        <svg-image
                                            class="icon hover-icon"
                                            icon="document-missing"
                                            height="18px"
                                            width="18px"
                                        />
                                    </div>
                                </li>
                            </ul>
                            <ul class="lens options" v-else>
                                <li
                                @click="formDataSave('lens', lens, false, true)"
                                class="option"
                                :class="{ active: reactiveSelectedValues.lens && reactiveSelectedValues.lens.id == lens.id, 'px-2' : showIsolineImages }"
                                :key="lens.id"
                                v-for="lens in reactiveProductConfigurator.lenses">
                                    <span>{{ lens.name }}</span>
                                    <img
                                        v-if="!showIsolineImages || (showIsolineImages && getIsolineFilePath(lens.isoline_diagram_file_path) !== '')"
                                        :src="`${apiVizuloUrl}${showIsolineImages ? getIsolineFilePath(lens.isoline_diagram_file_path) : lens.file_path}`"
                                        :class="{'isoline-image': showIsolineImages}"
                                    >
                                    <img
                                        v-else
                                        class="w-100 h-63"
                                        v-bind:src="require(`~/assets/images/consoles/console-0.svg`)"
                                        :alt="lens.name"
                                    >
                                    <span :class="{ 'm-0' : showIsolineImages }" class="check"></span>
                                    <div class="document-missing attribute-tooltip"
                                        @mouseover="showLensTooltip = true"
                                        @mouseleave="showLensTooltip = false"
                                        v-if="lens.no_photometric_file"
                                    >
                                        <svg-image
                                            class="icon hover-icon"
                                            icon="document-missing"
                                            height="18px"
                                            width="18px"
                                        />
                                    </div>
                                </li>
                                <li class="option additional-option-lens attribute-tooltip" :class="{ hide: selectedValues.configuratorMode !== 'basic' }" @click="setConfiguratorMode()">
                                    <svg-image
                                        class="icon hover-icon"
                                        icon="plus"
                                        height="26"
                                        width="26"
                                        color="grey"
                                    />
                                    <span class="attribute-tooltiptext extra-options" v-html="extraOptionsText"></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="base-attribute">
                    <div class="flex vw-10">
                        <p>{{ $t('Color rendering index') }}</p>
                        <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['color_rendering_index']">
                            <svg-image
                                class="icon hover-icon"
                                icon="info"
                            />
                            <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['color_rendering_index']"></span>
                        </div>
                    </div>
                    <ul class="options" v-if="reactiveSelectedValues.lens">
                        <li class="option additional-option attribute-tooltip" :class="{ hide: selectedValues.configuratorMode !== 'basic' }" @click="setConfiguratorMode()">
                            <svg-image
                                class="icon hover-icon"
                                icon="plus"
                                height="20"
                                width="20"
                            />
                            <span class="attribute-tooltiptext extra-options" v-html="extraOptionsText"></span>
                        </li><li
                            class="option"
                            :class="{ active: reactiveSelectedValues.cri && cri.id == reactiveSelectedValues.cri.id, hide: selectedValues.configuratorMode === 'basic' &&
                            !reactiveSelectedValues.typicalValues['cri'].includes(parseInt(cri.id))}"
                            @click="formDataSave('cri', cri, false, true)"
                            :key="cri.id"
                            v-for="cri in reactiveProductConfigurator.cri">
                            <span>{{ cri.name }}</span>
                            <div class="typical-value" v-if="showTypicalValue('cri', cri.id) && selectedValues.configuratorMode !== 'basic'">
                                <svg-image
                                    height="13"
                                    icon="star"
                                    width="13"
                                />
                                <span class="typical-attribute-tooltiptext">{{ $t('Typical') }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="base-attribute">
                    <div class="flex vw-10">
                        <p>{{ $t('Color temperature') }}</p>
                        <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['color_temperature']">
                            <svg-image
                                class="icon hover-icon"
                                icon="info"
                            />
                            <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['color_temperature']"></span>
                        </div>
                    </div>
                    <ul class="options" v-if="reactiveSelectedValues.lens && reactiveSelectedValues.cri">
                        <li class="option additional-option attribute-tooltip" :class="{ hide: selectedValues.configuratorMode !== 'basic' }" @click="setConfiguratorMode()">
                            <svg-image
                                class="icon hover-icon"
                                icon="plus"
                                height="20"
                                width="20"
                            />
                            <span class="attribute-tooltiptext extra-options" v-html="extraOptionsText"></span>
                        </li><li
                            class="option"
                            :class="{ active: reactiveSelectedValues.colorTemperature && colorTemperature.id == reactiveSelectedValues.colorTemperature.id, hide: selectedValues.configuratorMode === 'basic' && !reactiveSelectedValues.typicalValues['colorTemperature'].includes(parseInt(colorTemperature.id))}"
                            @click="formDataSave('colorTemperature', colorTemperature, false, true)"
                            :key="colorTemperature.id"
                            v-for="colorTemperature in reactiveProductConfigurator.colorTemperatures">
                            <span>{{ colorTemperature.name }}</span>
                            <div class="typical-value" v-if="showTypicalValue('colorTemperature', colorTemperature.id) && selectedValues.configuratorMode !== 'basic'">
                                <svg-image
                                    height="13"
                                    icon="star"
                                    width="13"
                                />
                                <span class="typical-attribute-tooltiptext">{{ $t('Typical') }}</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="align-end flex-wrap" v-if="selectedValues.configuratorMode === 'advanced'">
                    <div class="base-attribute w-100 border-bot-light" :class="{ 'no-border': reactiveSelectedValues.constantLightOutput}">
                        <div class="flex vw-10">
                            <p>{{ $t('Constant light output') }}</p>
                            <div class="attribute-tooltip flex" v-if="selectedValues.general.attributeInfo['constant_light_output']">
                                <svg-image
                                    class="icon hover-icon"
                                    icon="info"
                                />
                                <span class="attribute-tooltiptext" v-html="selectedValues.general.attributeInfo['constant_light_output']"></span>
                            </div>
                        </div>
                        <div class="flex-column">
                            <ul class="options">
                                <li
                                    class="yes-no no"
                                    :class="{ active: !reactiveSelectedValues.constantLightOutput}"
                                    @click="formDataSave('constantLightOutput', false, false, true)"
                                >
                                    <span>{{ $t('No') }}</span>
                                </li><li
                                    class="yes-no yes"
                                    :class="{ active: reactiveSelectedValues.constantLightOutput}"
                                    @click="formDataSave('constantLightOutput', true, false, true)"
                                >
                                    <span>{{ $t('Yes') }}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="clo-options w-100" v-if="reactiveSelectedValues.constantLightOutput">
                        <div class="flex-right mb-2">
                            <div class="flex-end mr-4 initial-output" :class="{ 'initial-output-firefox': isFirefox()}">
                                <p>{{ $t('Initial output') }}</p>
                                <input
                                    type="text"
                                    name="initialOutput"
                                    placeholder="%"
                                    v-model="reactiveSelectedValues.initialOutput"
                                    @change="formDataSave('initialOutput', reactiveSelectedValues.initialOutput, false, true)"
                                >
                            </div>
                            <div class="flex-end lifetime mr-2" :class="{ 'lifetime-firefox': isFirefox()}">
                                <p>{{ $t('Lifetime') }}</p>
                                <input
                                    type="text"
                                    name="lifeTime"
                                    placeholder="hours"
                                    v-model="selectedValues.step_2.lifeTime"
                                    @change="formDataSave('lifeTime', selectedValues.step_2.lifeTime, false, true)"
                                >
                            </div>
                        </div>
                        <p class="warning-message" v-if="warningMessage['target'] === 'initialOutput' && warningMessage['text'].length > 0">{{ warningMessage['text'] }}</p>
                        <p class="warning-message" v-if="warningMessage['target'] === 'lifeTime' && warningMessage['text'].length > 0">{{ warningMessage['text'] }}</p>
                    </div>
                </div>
                <div class="base-attribute flux-power">
                    <ul class="options flex">
                        <li
                            class="yes-no no"
                            :class="{ active: reactiveSelectedValues.fluxPower.selected === 'flux', 'support-long-text': storeCode === 'pl' }"
                            @click="formDataSave('selected', 'flux', true)">
                            <span>{{ $t('Flux') }}</span>
                        </li>
                        <li
                            class="yes-no yes"
                            :class="{ active: reactiveSelectedValues.fluxPower.selected === 'power', 'widht-80': storeCode === 'pl' }"
                            @click="formDataSave('selected', 'power', true)">
                            <span>{{ $t('Power') }}</span>
                        </li>
                    </ul>
                    <div class="flex-center" v-if="reactiveSelectedValues.originalMaxFlux > 0 && !loading.attributes">
                        <span v-if="reactiveSelectedValues.fluxPower.selected === 'power'">{{reactiveSelectedValues.minPower}}</span>
                        <span v-else>{{reactiveSelectedValues.minFlux}}</span>
                        <div class="relative-pos" v-if="reactiveSelectedValues.fluxPower.selected === 'flux'">
                            <input
                                class="vw-26"
                                @change="formDataSave('flux', reactiveSelectedValues.fluxPower.flux, false, true)"
                                type="range"
                                v-model="reactiveSelectedValues.fluxPower.flux"
                                :min="reactiveSelectedValues.minFlux"
                                :max="reactiveSelectedValues.maxFlux"
                            >
                            <input
                                type="text"
                                class="bubble"
                                @change="formDataSave('flux', reactiveSelectedValues.fluxPower.flux, false, true)"
                                v-model="reactiveSelectedValues.fluxPower.flux"
                                :style="{ 'left': `calc(${fluxLocation}% + (${4 - fluxLocation * 0.15}px))`}"
                                :class="{ 'warning': warningMessage['target'] === 'fluxPower' && warningMessage['text'].length > 0 }"
                            >
                            <p class="warning-message" v-if="warningMessage['target'] === 'fluxPower' && warningMessage['text'].length > 0">{{ warningMessage['text'] }} {{reactiveSelectedValues.minFlux}} - {{reactiveSelectedValues.maxFlux}}!</p>
                        </div>
                        <div class="relative-pos" v-else>
                            <input
                                class="vw-26"
                                @change="formDataSave('power', reactiveSelectedValues.fluxPower.power, false, true)"
                                type="range"
                                v-model="reactiveSelectedValues.fluxPower.power"
                                :min="reactiveSelectedValues.minPower"
                                :max="reactiveSelectedValues.maxPower"
                            >
                            <input
                                type="text"
                                class="bubble"
                                @change="formDataSave('power', reactiveSelectedValues.fluxPower.power, false, true)"
                                v-model="reactiveSelectedValues.fluxPower.power"
                                :style="{ 'left': `calc(${powerLocation}% + (${4 - powerLocation * 0.15}px))` }"
                                :class="{ 'warning': warningMessage['target'] === 'fluxPower' && warningMessage['text'].length > 0 }"
                            >
                            <p class="warning-message" v-if="warningMessage['target'] === 'fluxPower' && warningMessage['text'].length > 0">{{ warningMessage['text'] }} {{reactiveSelectedValues.minPower}} - {{reactiveSelectedValues.maxPower}}!</p>
                        </div>
                        <span v-if="reactiveSelectedValues.fluxPower.selected === 'power'">{{reactiveSelectedValues.maxPower}}</span>
                        <span v-else>{{reactiveSelectedValues.maxFlux}}</span>
                    </div>
                    <div v-else class="flex-center">
                        <div v-if="loading.attributes && reactiveSelectedValues.colorTemperature" class="flex-center">
                            <div>{{ $t('Loading flux and power slider') }}...</div>
                            <SkeletonLoader class="small-loader"/>
                        </div>
                    </div>
                </div>
                <div class="warning-message flex-center" v-if="selectedValues.general.unfinishedItems && selectedValues.general.unfinishedItems.length > 0">
                <p class="mr-2">{{ $t('Required fields') }}:</p>
                <p v-html="selectedValues.general.unfinishedItems.join(', ')"></p>
            </div>
        </div>
        <div class="hide">
            {{ lensesLength }}
        </div>
        <div v-if="reactiveSelectedValues.isComplete" class="arrow-down" @click="changeActiveStep('step_3')">
            <svg-image
                class="icon hover-icon"
                icon="chevron_down"
                height="36"
                width="36"
            />
        </div>
        <IssueReportPopup
            :visible="openConfiguratorPopup"
            :userComment="userComment"
            @close="openConfiguratorPopup = !openConfiguratorPopup"
            @sendReport="sendReport"
        />
    </div>
    <div
        v-if="activeStep !== 'step_2' && activeStep !== 'step_6'"
        class="base-attribute-button"
        @click="changeActiveStep('step_2')"
        :class="{ 'base-attribute-button-close': isCloseToActiveStep, 'nextStep': isNextStep }"
    >
        <span>
            {{ $t('Step') }} 2 - {{ $t('Light') }}
        </span>
    </div>
  </div>
</template>
<script>
import { useProductConfigurator } from '@/modules/vizulo/productConfigurator';
import {
    defineComponent,
    ref,
    computed,
} from '@nuxtjs/composition-api';
import { useVizulo } from '~/composables';
import IssueReportPopup from '~/components/Common/IssueReportPopup.vue';

export default defineComponent({
    name: 'Step_1',
    components: {
        IssueReportPopup,
    },
    data: () => (
		{
            showLensTooltip: false,
            extraOptionsText: 'Switch to advanced mode to access more options.',
		}
	),
    props: {
        activeStep: String,
        isCloseToActiveStep: Boolean,
        storeCode: String,
	},
    setup(props, {emit}) {
        const {
            updateSelectedValues,
            selectedValues,
            productConfigurator,
            getLenses,
            loading,
            getColorTemperature,
            resetFormData,
            createBugReportInAsana
        } = useProductConfigurator();
        const { getApiVizuloStorage } = useVizulo();
        const apiVizuloUrl = getApiVizuloStorage();
        const step = 'step_2';
        const stepIndex = 2;
        const warningMessage = ref({
            target: '',
            text: ''
        });
        const defaultWarningText = 'Please choose value between ';
        const searchInput = ref('');
        const filteredLenses = ref([]);
        const openFilters = ref(false);
        const openConfiguratorPopup = ref(false);
        const userComment = ref({});
        const showIsolineImages = ref(false);
        const activeFilters = ref({
            application: [],
            ledDensity: [],
            distributionType: [],
            ledModuleCategory: [],
        });

        const minMaxValues = {
            minLifeTime: 50000,
            maxLifeTime: 100000,
            minInitialOutput: 80,
            maxInitialOutput: 100,
        }

        const fluxPower = ref({
            selected: 'flux',
            power: 0,
            flux: 0,
        });

        const reactiveProductConfigurator = computed(() => {
            if (productConfigurator.value.step_2.fluxPower) {
                fluxPower.value = productConfigurator.value.step_2.fluxPower;
            }
            return productConfigurator.value.step_2 ?? [];
        });

        const reactiveSelectedValues = computed(()=>{
            return selectedValues.value.step_2 ?? [];
        })

        const lensesLength = computed(() => {
            return productConfigurator.value.step_2.lenses.length;
        })

        const reactiveSelectedProductVersions = computed(()=>{
            return selectedValues.value.step_1.productVersions ?? [];
        })

        const filteredProductVersions = computed(() => {
            return productConfigurator.value.step_1.productVersions.filter((version) => {
                return version.id === 68;
            })
        })

        const fluxLocation = computed(()=>{
            let position = Number(((reactiveSelectedValues.value.fluxPower.flux - reactiveSelectedValues.value.minFlux) * 100) / (reactiveSelectedValues.value.maxFlux - reactiveSelectedValues.value.minFlux));

            if (position > 100) {
                position = 100;
            } else if(position < 0) {
                position = 0;
            }

            return position;
        })

        const powerLocation = computed(()=>{
            let position = Number(((reactiveSelectedValues.value.fluxPower.power - reactiveSelectedValues.value.minPower) * 100) / (reactiveSelectedValues.value.maxPower - reactiveSelectedValues.value.minPower));

            if (position > 100) {
                position = 100;
            } else if(position < 0) {
                position = 0;
            }

            return position;
        })

        const isNextStep = computed(()=>{
			let activeStepIndex = props.activeStep.charAt(props.activeStep.length - 1);

			if (parseInt(activeStepIndex) === stepIndex - 1 && !reactiveSelectedValues.value['isComplete']) {
				return true;
			}

			return false;
        })

        const formDataSave = async (attribute, value, cancelUpdate = false, selectedByUser = false) => {
            if (attribute === 'power' || attribute === 'flux' || attribute === 'selected') {
                isInRange(attribute, value);
                updateSelectedValues(step, 'fluxPower', fluxPower, cancelUpdate, selectedByUser);
            } else if (attribute === 'initialOutput') {
                warningMessage.value['text'] = '';
                if ( value >= minMaxValues.minInitialOutput && value <= minMaxValues.maxInitialOutput ) {
                    updateSelectedValues(step, attribute, value, cancelUpdate, selectedByUser);
                } else {
                    updateSelectedValues(step, attribute, value > minMaxValues.maxInitialOutput ? minMaxValues.maxInitialOutput : minMaxValues.minInitialOutput, cancelUpdate, selectedByUser);
                    warningMessage.value['target'] = 'initialOutput';
                    warningMessage.value['text'] = `${defaultWarningText} ${minMaxValues.minInitialOutput} - ${minMaxValues.maxInitialOutput}%`;
                }
            } else if (attribute === 'lifeTime') {
                warningMessage.value['text'] = '';
                value = value.replace(' ', '');
                if (Number(value) >= minMaxValues.minLifeTime && Number(value) <= minMaxValues.maxLifeTime) {
                    if (value.length > 3) {
                        value = [value.slice(0, value.length - 3), ' ', value.slice(value.length - 3)].join('');
                    }

                    updateSelectedValues(step, attribute, value, cancelUpdate, selectedByUser);
                } else {
                    updateSelectedValues(step, attribute, Number(value) > minMaxValues.maxLifeTime ? '100 000' : '50 000', cancelUpdate, selectedByUser);
                    warningMessage.value['target'] = 'lifeTime';
                    warningMessage.value['text'] = `${defaultWarningText} 50 000 - 100 000 h`;
                }
            } else {
                if (attribute === 'productVersions') {
                    updateSelectedValues('step_1', attribute, value, cancelUpdate, selectedByUser);
                } else {
                    updateSelectedValues(step, attribute, value, cancelUpdate, selectedByUser);
                }
            }
        };

        const setSelectedVersion = (selected, productVersion) => {
            productVersion['selected'] = selected;
            selectedValues.value.step_1.productVersions[productVersion.id] = productVersion;
            formDataSave('productVersions', selectedValues.value.step_1.productVersions);

            if ( reactiveSelectedValues.value.initialOutput === 0 && reactiveSelectedValues.value.lifeTime === '' ) {
                if( productVersion.id === 68 && !selected) {
                    formDataSave('initialOutput', 98);
                } else {
                    formDataSave('initialOutput', 90);
                }

                formDataSave('lifeTime', '100000');
            }

            getData();
            formDataSave('versionsAreEdited', true);
        };

        const manageSecondaryFiltersOnClose = () => {
            openFilters.value = !openFilters.value;

            if (openFilters.value == false) {
                activeFilters.value.ledDensity = [];
                activeFilters.value.distributionType = [];

                filterLenses('', '', true);
            }
        }

        const isInRange = (attribute, value) => {
            warningMessage.value['target'] = 'fluxPower';
            if (attribute == 'selected') {
                warningMessage.value['text'] = '';
                fluxPower.value.selected = value;
            }
            if (attribute === 'power') {
                warningMessage.value['text'] = '';
                fluxPower.value.flux = 0;
                if (parseInt(value) > reactiveSelectedValues.value.maxPower) {
                    fluxPower.value.power = reactiveSelectedValues.value.maxPower;
                    warningMessage.value['text'] = defaultWarningText;
                } else if (parseInt(value) < reactiveSelectedValues.value.minPower) {
                    fluxPower.value.power = reactiveSelectedValues.value.minPower;
                    warningMessage.value['text'] = defaultWarningText;
                } else {
                    fluxPower.value.power = value;
                }
            } else if (attribute === 'flux') {
                warningMessage.value['text'] = '';
                fluxPower.value.power = 0;
                if (parseInt(value) > reactiveSelectedValues.value.maxFlux) {
                    fluxPower.value.flux = reactiveSelectedValues.value.maxFlux;
                    warningMessage.value['text'] = defaultWarningText;
                } else if (parseInt(value) < reactiveSelectedValues.value.minFlux) {
                    fluxPower.value.flux = reactiveSelectedValues.value.minFlux;
                    warningMessage.value['text'] = defaultWarningText;
                } else {
                    fluxPower.value.flux = value;
                }
            }
        }

        const showTypicalValue = (attribute, value) => {
            if (reactiveSelectedValues.value[attribute]) {
                return parseInt(reactiveSelectedValues.value[attribute].id) !== parseInt(value) && reactiveSelectedValues.value.typicalValues[attribute].includes(parseInt(value));
            } else {
                return reactiveSelectedValues.value.typicalValues[attribute].includes(parseInt(value));
            }
        }

		const findItems = () => {
            if (isFiltersActive) {
                activeFilters.value = {
                    application: [],
                    ledDensity: [],
                    distributionType: [],
                    ledModuleCategory: []
                }
                selectedValues.value.step_2.LEDCategoryName = '';
                openFilters.value = false;
            }

			if (searchInput.value.length > 0) {
                filteredLenses.value = reactiveProductConfigurator.value.lenses.filter((lens) => {
                    return lens.name.toLowerCase().includes(searchInput.value.toLocaleLowerCase());
                })
			} else {
                filteredLenses.value = reactiveProductConfigurator.value.lenses;
            }
		}

        const filterLenses = (type, value, secondaryFiltersDisabled = false, useExistingFilters = false) => {
            preSetFilters(type, value, secondaryFiltersDisabled, useExistingFilters);

            if (reactiveProductConfigurator.value.ledModuleCategories.length === 1 && activeFilters.value.ledModuleCategory.length === 0) {
                activeFilters.value.ledModuleCategory.push(reactiveProductConfigurator.value.ledModuleCategories[0]);
            }

            filteredLenses.value = [];
            filteredLenses.value = reactiveProductConfigurator.value.lenses.filter((lens) => {
                let removeLens = true;
                let applicationMatched = false;

                activeFilters.value.application.forEach(element => {
                    if (lens.application_group_name && lens.application_group_name.includes(element)){
                        applicationMatched = true;
                    }
                });

                if ( activeFilters.value.distributionType.length > 0 && !activeFilters.value.distributionType.includes(lens.distribution_type) ){
                    removeLens = false;
                }

                if ( activeFilters.value.ledDensity.length > 0 && !activeFilters.value.ledDensity.includes(lens.lens_type_id) ){
                    removeLens = false;
                }

                if (!applicationMatched && activeFilters.value.application.length > 0) {
                    removeLens = false;
                }

                let hasCategory = false;
                if (activeFilters.value.ledModuleCategory.length > 0) {
                    let categories = lens.led_module_category.split(',');

                    categories.forEach((category)=> {
                        if (activeFilters.value.ledModuleCategory.includes(category)) {
                            hasCategory = true;
                        }
                    })
                }

                if ( activeFilters.value.ledModuleCategory.length > 0 && !hasCategory ){
                    removeLens = false;
                }
                document.activeElement.blur();

                return removeLens;
            })
        }

        const preSetFilters = (type, value, secondaryFiltersDisabled, useExistingFilters) => {
            searchInput.value = '';

            if (useExistingFilters && !isFiltersActive.value) {
                return;
            }

            if (!useExistingFilters) {
                if (!secondaryFiltersDisabled) {
                    if (type == 'ledModuleCategory') {
                        selectedValues.value.step_2.LEDCategoryName = selectedValues.value.step_2.LEDCategoryName == value ? '' : value;
                        activeFilters.value[type] = activeFilters.value[type].includes(value) ? [] : [value];
                    } else {
                        activeFilters.value[type] = manageCollection(activeFilters.value[type], value);
                    }

                }
            }
        }

        const manageCollection = (collection, value) => {
            if (!collection.includes(value)) {
                collection.push(value);
            } else {
                collection = collection.filter((filter) => {
                    return filter !== value;
                })
            }
            return collection;
        }

        const isFiltersActive = computed(()=>{
            let isActive = false;
            for (let activeFilter in activeFilters.value) {
                if ( activeFilters.value[activeFilter].length !== 0 ) {
                    isActive = true;
                }
            }

            return isActive;
        })

        const changeActiveStep = async (value) => {
            emit('changeStep',(value));
        }

        const isFirefox = () => {
            if (navigator.userAgent.includes('Firefox')) {
                return true;
            } else {
                return false
            }
        }

        const getData = async () => {
            getLenses(false).then(() => {
                if (selectedValues.value.step_2.cri !== null) {
                    getColorTemperature();
                }
            });
        }

        const sendReport = (sentWithSubmitButton) => {
            createBugReportInAsana(userComment.value.message ?? '', sentWithSubmitButton);
            openConfiguratorPopup.value = false;
        }

        const getIsolineFilePath = (filePath) => {
            if (filePath) {
                return filePath;
            } else {
                return '';
            }
        }

        const setConfiguratorMode = async () => {
            emit('setConfiguratorMode', 'advanced');
        }

        return {
            formDataSave,
            getLenses,
            changeActiveStep,
            getColorTemperature,
            showTypicalValue,
            findItems,
            filterLenses,
            setSelectedVersion,
            isFirefox,
            resetFormData,
            manageSecondaryFiltersOnClose,
            sendReport,
            getIsolineFilePath,
            setConfiguratorMode,
            lensesLength,
            reactiveSelectedProductVersions,
            apiVizuloUrl,
            reactiveSelectedValues,
            reactiveProductConfigurator,
            selectedValues,
            fluxPower,
            fluxLocation,
            powerLocation,
            searchInput,
            isNextStep,
            loading,
            warningMessage,
            filteredLenses,
            openFilters,
            activeFilters,
            isFiltersActive,
            filteredProductVersions,
            openConfiguratorPopup,
            userComment,
            showIsolineImages,
        };
    }
});
</script>

<style lang="scss" scoped>
.center {
    overflow: hidden;
}

.light-border {
    align-items: baseline;
}

.selected-lens {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border: 1px solid #c4c4c4;
    background: rgba(0,0,0, 0.01);
    margin-top: 11px;
    margin-left: 12px;
    margin-bottom: 1.2rem;

    @media (min-width: 1200px) {
        flex-direction: column;
        height: 294px;
        margin-left: 0;
        margin-bottom: 0;
    }

    .images {
        display: flex;
        flex-direction: row;
        height: inherit;
        margin-top: 0.6rem;

        @media (min-width: 1200px) {
            flex-direction: column;
            justify-content: space-around;
            margin-top: 0;
        }
    }

    .light-border {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (min-width: 1200px) {
            margin: 0;
        }
    }

    .trimmed {
        width: 140px;
        height: 85px;
        position: relative;
        bottom: 10px;

        @media (min-width: 1400px) {
            width: 180px;
            height: 105px;
            position: relative;
            bottom: 5px;
        }
    }

    img {
        width: 163px;
    }

    span {
        font-weight: bold;
    }
}

.lens-template {
    overflow-y: auto;
    scroll-behavior: auto;
    align-items: start;
    overflow-y: unset;

    .filters {
        padding: 10px 10px 10px 3px;
        width: 100%;

        @media (min-width: 1200px) {
            width: 760px;
        }

        @media (min-width: 1400px) {
            width:  870px;
        }

        .base-attribute {
            margin-left: 12px;
            .vw-10 {
                width: 14rem;
            }

            p {
                margin-left: 12px;
            }
        }
    }

    .flex-start {
        justify-content: space-between;
        align-items: center;

        .filters {
            padding: 12px;
            width: 870px;
            align-items: center;

            input {
                border: none;
                border-bottom: 2px solid var(--c-black);
                border-radius: unset;
                width: 20rem;

                &:focus {
                   outline: none;
                }
            }
        }
    }

    .base-attribute {
        p {
            font-weight: normal !important;
            font-size: 16px !important;
        }

        .option {
            text-align: center;
            &::first-letter {
                text-transform: uppercase !important;
            }
        }
    }

    .flex-center {
        cursor: pointer;
        p {
            margin-right: 1rem;
        }
    }
}

.options {
    padding: 0;
    margin: 0;
    font-family: var(--font-family--primary-bold);

    .option, .yes-no {
        list-style: none;
        font-size: 14px;
        line-height: 18px;
        background-color: var(--c-white);
        cursor: pointer;
    }

    .yes, .no {
        width: 70px;
        text-align: center;
        padding: 9px 12px;
        border: 1px solid #979797;
        transition: all ease-in 300ms;
        display: inline-block;

        &.active,
        &:hover {
            color: var(--c-white);
            background-color: var(--c-black);
            border: 1px solid var(--c-black);
            text-decoration: none;
        }
    }

    .support-long-text {
        white-space: nowrap;
        width: auto !important;
    }

    .widht-80 {
        width: 80px !important;
    }

    &:not(.lens) {
        .option {
            padding: 9px 12px;
            position: relative;
            border-radius: 20px;
            border: 1px solid #979797;
            margin: 6px 15px 6px 0;
            cursor: pointer;
            transition: all ease-in 300ms;
            display: inline-block;

            &:focus {
                outline: none;
            }
        }
    }

    &.lens {
        max-height: 440px;
        min-height: 140px;
        width: 100%;
        overflow: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        scrollbar-width: thin;          /* "auto" or "thin" */
        scrollbar-color: var(--c-black) var(--c-lighter-gray);   /* scroll thumb and track */

        @media (min-width: 1200px) {
            width: 760px;
            justify-content: flex-end;
        }

        @media (min-width: 1400px) {
            width:  870px;
        }

        .option {
            display: inline-block;
            border: 1px solid #c4c4c4;
            padding: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 11px;
            width: 100px;
            border-radius: initial !important;
            background: var(--c-white) !important;
            color: var(--c-black) !important;
            position: relative;

            &:hover {
                border: 1px solid var(--c-black) !important;
                box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
                -webkit-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
                -moz-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
            }

            &.active {
                border: 1px solid var(--c-black) !important;
                box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
                -webkit-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
                -moz-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
            }

            span {
                margin-bottom: 10px;
                display: inline-block;
                font-family: var(--font-family--primary);
            }

            &.active {
                .check::before {
                    display: block;
                    position: absolute;
                    content: '';
                    border-radius: 100%;
                    height: 12px;
                    width: 12px;
                    top: 3px;
                    left: 3px;
                    margin: auto;
                    background: var(--c-black);
                    transition: background 0.25s linear;
                    -webkit-transition: background 0.25s linear;
                }
            }

            .isoline-image {
                max-height: 63px;
            }
        }

    }
}

.w-100 {
    .container {
        .consoles {
            .special-option {
                &:hover {
                    color: var(--c-white);
                    background-color: var(--c-black);
                    border: 1px solid var(--c-black);
                    text-decoration: none;
                }
            }
        }
    }
}

.configurator-mode {
    font-size: 12px !important;
    display: flex !important;
    font-weight: normal !important;

    .basic {
        padding: 0.2rem 0.8rem !important;
        border: 1px solid var(--_c-gray-secondary) !important;
        color: var(--c-black) !important;
    }

    .advanced {
        border: 1px solid var(--_c-gray-secondary) !important;
    }

    .active {
        background: var(--_c-light-primary) !important;
        color: var(--c-black) !important;
    }

}

.clo-options {
    border-bottom: 1px solid var(--_c-gray-accent);
    .warning-message-2 {
        position: unset;
        text-align: right;
    }
}

.flex-right {
    display: flex;
    justify-content: flex-end;

    input {
        width: 8rem;
        padding: 0.35em 0.25em;
        margin-left: 0.7em;
        width: 110px;
        text-align: center;
    }

    .initial-output, .lifetime {
        position: relative;
    }

    .initial-output{
        input {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='85' y='20' fill='gray' font-size='16' font-family='arial'>%</text></svg>");
            background-repeat: no-repeat;
        }
    }

    .lifetime {
        input {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='90' y='20' fill='gray' font-size='16' font-family='arial'>h</text></svg>");
            background-repeat: no-repeat;
        }
    }
    .initial-output-firefox{
        input {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='85' y='24' fill='gray' font-size='16' font-family='arial'>%</text></svg>") !important;
        }
    }

    .lifetime-firefox {
        input {
            background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='31px' width='110px'><text x='90' y='24' fill='gray' font-size='16' font-family='arial'>h</text></svg>") !important;
        }
    }
}

.bubble {
    padding: 3px 6px;
    position: absolute;
    border-radius: 5px;
    width: 54px;
    font-size: 14px;
    font-weight: bold;
    bottom: 0;
    margin-bottom: 2rem;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
}

.check {
    display: block;
    border: 1px solid var(--c-black);
    border-radius: 100%;
    height: 20px;
    width: 20px;
    z-index: 1;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;
    position: relative;
    float: left;
    margin-top: 10px;
}

.no-border {
    border-bottom: none !important;
}


h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 12px 0;
}

.collapse-enter-active,
.collapse-leave-active {
  transition: all 0.3s ease-in-out;
  height: 630px;
}

.collapse-enter-from,
.collapse-leave-to {
    height: 0px;
}

.align-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.baseline {
    align-items: baseline;
    justify-content: flex-start;
    flex-direction: column;

    @media (min-width: 1200px) {
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: row;
    }

    @media (min-width: 1400px) {
        align-items: flex-start;
        justify-content: space-between;
    }
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flux-power {
    ul {
        margin: 1.1rem 0 2.3rem;

        @media (min-width: 1200px) {
            margin: 1.1rem 0;
        }
    }

    input[type=range]{
       height: 1.2rem;
    }

    .small-loader {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        margin: 0 1rem !important;
    }
}

.inactive-no {
    background-color:#02020269 !important;
}

.warning-message-2 {
    position: absolute;
    display: block;
    left: 16%;
    top: 50%;
    color: var(--_c-red-primary);
}

ul::-webkit-scrollbar{
    background: var(--c-lighter-gray);
    width: 2px;
}

ul::-webkit-scrollbar-thumb{
    background: var(--c-black);
}

.justify-content-center {
    & > * {
        cursor: pointer;
    }
}

.filters {
    .options {
        font-family: var(--font-family--primary);

        .w-8 {
            width: 8rem;
            border-radius: 0;

            &.active,
            &:hover {
                background: none;
                color: var(--c-black);
                border: 1.4px solid var(--c-black) !important;
                box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
                -webkit-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
                -moz-box-shadow: -1px -1px 1px 1px rgba(172, 172, 172, 0.55);
            }
        }
    }
}

.vw-26 {
    width: 14em !important;

    @media (min-width: 360px) {
        width: 16em !important;
    }


    @media (min-width: 400px) {
        width: 18em !important;
    }

    @media (min-width: 999px) {
        width: 26em !important;
    }
}

.document-missing {
    position: absolute;
    left: 0;
    margin-left: 0.5rem;
}

.document-missing-text {
    position: absolute;
    top: 12px;
    left: 50%;
    transform: translate(0, -50%);
}

.font-weight-normal {
    font-weight: normal !important;
}

.overflow-auto {
    overflow: inherit !important;
    .small.attribute-tooltip {
        margin: 0;
        z-index: 2;

        .attribute-tooltiptext {
            right: 4.2rem;
            top: 1.7rem;
        }
    }
}

.additional-option {
    padding: 7px 8px 5px !important;
    position: relative;
    top: 3px;

    span {
        width: 24px;
        justify-content: center;
        color: var(--_c-gray-primary);
    }
}

.additional-option-lens {
    justify-content: center;
    color: var(--c-lighter-gray);

    span {
        color: var(--_c-gray-primary);
    }
}

.h-63 {
    height: 63px;
}

.extra-options {
    top: -4rem !important;
    right: 3rem !important;
}
</style>