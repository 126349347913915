<template>
    <div class="lap-wing-banner flex-column align-start">
        <p class="font-size-large mb-0">{{ $t('New arrivals in') }}</p>
        <h1>{{ $t('Lapwing family') }}</h1>
        <div class="flex-center">
            <a :href="'products' + categoryUrl + '?category_key=Lapwing'" class="flex-center" style="color: var(--c-white);">
                {{ $t('Explore products') }}
                <svg-image
                    class="arrow-link"
                    height="20"
                    icon="arrow-link"
                    width="20"
                />
            </a>
        </div>
    </div>
</template>
<script>
export default {
    name: 'LapWingBanner',
	props: {
		categoryUrl: {
			type: String,
			required: true
		},
	},
};
</script>
<style lang="scss" scoped>
.lap-wing-banner {
    .font-size-large {
        font-size: 24px;
        font-family: var(--font-family--secondary);
    }

    h1 {
        margin-top: 8px !important;
        font-size: 46px !important;
    }

    div {
        margin: 0 !important;
        border: 1px solid var(--c-white);
        padding: 7px 14px;
        font-family: var(--font-family--secondary) !important;

        a {
            font-size: 1rem !important;
            font-weight: normal !important;
        }
    }
}
</style>